<template>
  <div class="content">
    <audio id="audio" :src="require('../assets/music/bgmusic.mp3')"></audio>
    <div v-if="playing" @click="pause" class="sound-btn">
      <img class="img" src="../assets/image/sound.png" alt="">
    </div>
    <div v-else @click="play" class="sound-btn">
      <img class="img" src="../assets/image/nosound.png" alt="">
    </div>
    <img class="main-img" src="https://www.zhaowanqing.com/files/fengmian.jpg" width="240" alt="">
    <div class="label">欢迎来到婉晴的世界!</div>
    <div class="label">正在装修中...</div>
    <div
        style="position: absolute;bottom: 10px;color:#939393;display: flex;flex-direction: column;align-items: center;">
      <a href="https://beian.miit.gov.cn">
        陕ICP备2022000230号
      </a>
      <a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=61010302000850"
         style="display: flex;align-items: center;">
        <img src="../assets/image/beian.png" style="margin: 0 5px;">
        陕公网安备 61010302000850号
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Home',
  data() {
    return {
      audio: null,
      playing: false,
    }
  },
  mounted() {
    this.audio = document.getElementById("audio");
  },
  methods: {
    play() {
      this.audio.play();
      this.playing = true;
    },
    pause() {
      this.audio.pause();
      this.playing = false;
    },
  },
}
</script>

<style>
.content {
  width: 100%;
  height: 100%;
  background-color: azure;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.main-img {
  border-radius: 10px;
  margin-bottom: 10px;
  border: hotpink dashed 2px;
}

.label {
  margin-top: 10px;
  font-weight: bold;
  font-size: 20px;
  color: hotpink;
}

.sound-btn {
  position: absolute;
  top: 0;
  right: 0;
  width: 30px;
  height: 30px;
  padding: 5px;
  margin: 10px;
  background-color: white;
  border-radius: 10px;
  border: #1296db solid 3px;
  cursor: pointer;
}

.sound-btn .img {
  width: 100%;
  height: 100%;
}
</style>
